import { Card, CardContent } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import UnderlinedTitle from "../UnderlinedTitle/UnderlinedTitle";

const PageCard = ({ className, color, title, children }) => {
  return (
    <Container maxWidth="xl" className={className}>
      <Card
        sx={{
          bgcolor: color,
          color: "white",
          borderRadius: 8,
          minHeight: "80vh",
        }}
      >
        <CardContent>
          <UnderlinedTitle>{title}</UnderlinedTitle>
          {children}
        </CardContent>
      </Card>
    </Container>
  );
};

export default PageCard;
