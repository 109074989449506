import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DevButton, GithubButton, ViewButton } from "./Buttons";

const ProjectCard = (props) => {
  const [currButton, setCurrButton] = useState();

  useEffect(() => {
    switch (props.project.button) {
      case "github":
        setCurrButton(
          <GithubButton link={props.project.link} name={props.project.title} />
        );
        break;
      case "dev":
        setCurrButton(
          <DevButton link={props.project.link} name={props.project.title} />
        );
        break;
      case "view":
        setCurrButton(
          <ViewButton link={props.project.link} name={props.project.title} />
        );
        break;
      default:
        setCurrButton(
          <DevButton link={props.project.link} name={props.project.title} />
        );
    }
  }, [props.project]);

  return (
    <Card sx={{ borderRadius: 6, minWidth: { xs: 20, sm: 350 }, height: 225 }}>
      <CardHeader
        sx={{ bgcolor: "#6C63FF", height: 25 }}
        title={<h4>{props.project.title}</h4>}
      ></CardHeader>
      <CardContent>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item sx={{ minHeight: 62 }}>
            <p>{props.project.description}</p>
          </Grid>

          <Grid item sx={{ py: 2 }}>
            {currButton}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ProjectCard;
