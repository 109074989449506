import { Grid, TextField } from "@mui/material";
import React from "react";

const MyTextField = (props) => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="stretch"
    >
      <Grid item>
        <h4>{props.label}</h4>
      </Grid>

      <Grid item sx={{ pb: { xs: 1, xl: 2.5} }}>
        <TextField
          fullWidth
          hiddenLabel
          id={props.name}
          placeholder={props.placeholder}
          variant="filled"
          required={true}
          size={props.size}
          multiline={props.multiline}
          rows={props.rows}
          name={props.name}
          InputProps={{ disableUnderline: true }}
          sx={{
            bgcolor: "#D9D9D9",
            borderRadius: 2,
            "& label.Mui-focused": {
              color: "white",
            },
            "& .MuiFilledInput-underline:after": {
              borderBottomColor: "transparent",
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default MyTextField;
