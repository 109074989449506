import { Button } from "@mui/material";
import React from "react";
import { getAnalytics, logEvent } from "firebase/analytics";

const ProjectButton = ({ children, ...props }) => {
  const handleClick = () => {
    const analytics = getAnalytics();

    logEvent(analytics, props.name);
    if (props.link) {
      window.open(props.link, "_blank");
    }
  };

  return (
    <Button
      variant="contained"
      onClick={handleClick}
      disabled={props.link ? false : true}
      disableElevation
      startIcon={<img src={props.icon} alt="Link" />}
      sx={{
        textTransform: "none",
        fontFamily: "Nunito",
        fontWeight: "700",
        fontSize: "13px",
        minWidth: "8em",
        bgcolor: props.colorOne,
        ":hover": {
          bgcolor: props.colorTwo ?? props.colorOne,
          cursor: props.link ? "pointer" : "default",
        },
        ":disabled": {
          bgcolor: props.colorOne,
          color: props.colorThree,
        },
        color: props.colorThree,
        borderRadius: "10px",
      }}
    >
      {children}
    </Button>
  );
};

export default ProjectButton;
