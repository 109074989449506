import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";

export class Firebase {
  static getFirebase = () => {
    const firebaseConfig = {
      apiKey: "AIzaSyASlMQ_KrfddYZ3a37knb2xZ7RQknwZy6E",
      authDomain: "personal-portfolio-14366.firebaseapp.com",
      projectId: "personal-portfolio-14366",
      storageBucket: "personal-portfolio-14366.appspot.com",
      messagingSenderId: "558165432649",
      appId: "1:558165432649:web:f7e88f0d0a64f59cae2298",
      measurementId: "G-NCBYQ8Y07Z",
      automaticDataCollectionEnabled: true,
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);

    return analytics;
  };
}
