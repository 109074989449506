import React from "react";
import github from "../../../images/Projects/Buttons/githubImage.svg";
import dev from "../../../images/Projects/Buttons/devImage.svg";
import view from "../../../images/Projects/Buttons/viewImage.svg";
import ProjectButton from "../../../components/Buttons/ProjectButton";

const GithubButton = (props) => {
  return (
    <ProjectButton
      icon={github}
      colorOne="#EDFDF7"
      colorTwo="#D6F7E1"
      colorThree="#11825C"
      link={props.link}
      name={props.name}
    >
      Github
    </ProjectButton>
  );
};

const DevButton = (props) => {
  return (
    <ProjectButton
      icon={dev}
      colorOne="#EDEDEF"
      colorThree="#000000"
      link={props.link}
      name={props.name}
    >
      In Devlopment
    </ProjectButton>
  );
};

const ViewButton = (props) => {
  return (
    <ProjectButton
      icon={view}
      colorOne="#FFEED5"
      colorTwo="#FFE4BB"
      colorThree="#7D6E56"
      link={props.link}
      name={props.name}
    >
      View
    </ProjectButton>
  );
};

export { GithubButton, DevButton, ViewButton };
