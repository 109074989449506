import React, { useContext, useState } from "react";
import { Firebase } from "../service/firebase";

const FirebaseContext = React.createContext();

export const FirebaseProvider = ({ children }) => {
  const [firebase, setFirebase] = useState(Firebase.getFirebase());

  return (
    <FirebaseContext.Provider value={{ firebase, setFirebase }}>
      {children}
    </FirebaseContext.Provider>
  );
};

export const useFirebase = () => {
  const { firebase } = useContext(FirebaseContext);

  return { firebase };
};
