import { Card, CardContent, Grid } from "@mui/material";
import ArrowOutwardSharpIcon from "@mui/icons-material/ArrowOutwardSharp";
import React from "react";

const AboutCard = () => {
  return (
    <Card
      sx={{
        bgcolor: "#37515F",
        color: "white",
        borderRadius: 8,
        height: "100%",
      }}
    >
      <CardContent sx={{ px: 4, my: 0, py: 1.5, height: "100%" }}>
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
          sx={{ height: { xs: "80%", md: "90%" }, pb: 1 }}
        >
          <Grid item sx={{ width: "100%" }}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <p>DISCOVER WHO I AM</p>
              <ArrowOutwardSharpIcon />
            </Grid>
          </Grid>

          <Grid item>
            <h3>About me</h3>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AboutCard;
