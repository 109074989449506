import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import emailjs from "emailjs-com";
import React, { useRef } from "react";
import SendIcon from "@mui/icons-material/Send";
import MyTextField from "./MyTextField";
import { useNavigate } from "react-router-dom";

const Email = () => {
  const form = useRef();

  const navigate = useNavigate();


  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_h44m2z5",
        "template_vx00frq",
        form.current,
        "lSStcfTLymp5XK8LQ"
      )
      .then(
        (response) => {
          navigate("/")
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <Box>
      <form ref={form} onSubmit={sendEmail}>
        <MyTextField
          name="from_name"
          placeholder="Steve Rodgers"
          label="Name"
          size="small"
        />
        <MyTextField
          name="reply_to"
          placeholder="cap@avengers.com"
          label="Email"
          size="small"
        />
        <MyTextField
          name="message"
          placeholder="Assemble"
          label="Message"
          multiline={true}
          rows={5}
        />

        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item sx={{ py: 2 }}>
            <Button
              variant="outlined"
              type="submit"
              endIcon={<SendIcon />}
              sx={{
                textTransform: "none",
                fontFamily: "Inter",
                fontWeight: "600",
                fontSize: "15px",
                width: "8em",

                ":hover": {
                  color: "black",
                  border: "4px solid white",
                  borderRadius: "10px",
                  bgcolor: "white"
                },
                color: "white",
                border: "4px solid white",
                borderRadius: "10px",
              }}
            >
              Send
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Email;
