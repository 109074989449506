import "./App.css";
import Header from "./components/Header/index";
import Home from "./pages/Home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from "./pages/About";
import { AnimatePresence } from "framer-motion";
import Projects from "./pages/Projects";
import Contact from "./pages/Contact";
import { useTheme } from "./context/useTheme";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import CurrScreen from "./components/Analytics/CurrScreen";

function App() {
  const { theme } = useTheme();

  return (
    <Router>
      <div className={`App ${theme === "light" ? "themeLight" : "themeDark"}`}>

        {/* Auto Scroll to Top of Page on New Route  */}
        <ScrollToTop />

        {/* Google Analytics to track view on each route */}
        <CurrScreen />
        
        <Header />
        <Routes>
          <Route
            path="/"
            element={
              <AnimatePresence>
                <Home />
              </AnimatePresence>
            }
          />
          <Route
            path="/about"
            element={
              <AnimatePresence>
                <About />
              </AnimatePresence>
            }
          />
          <Route
            path="/projects"
            element={
              <AnimatePresence>
                <Projects />
              </AnimatePresence>
            }
          />
          <Route
            path="/contact"
            element={
              <AnimatePresence>
                <Contact />
              </AnimatePresence>
            }
          />
          <Route
            path="*"
            element={
              <AnimatePresence>
                <Home />
              </AnimatePresence>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
