import { Card, CardContent, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import landingImage from "../../../images/new_landing_image.svg";

const LandingCard = () => {
  return (
    <Card
      sx={{
        bgcolor: "#1C1C1C",
        color: "white",
        borderRadius: 8,
        height: "100%",
      }}
    >
      <CardContent sx={{ px: 6, mb: 6, mt: 3 }}>
        <Box sx={{ pb: 8 }}>
          <p>WELCOME</p>
          <h1>Hi, my</h1>
          <h1>name is Elliot.</h1>
          <p>I'M A SOFTWARE DEVELOPER</p>
        </Box>

        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <img src={landingImage} alt="Laptop & Phone" />
        </Grid>
      </CardContent>
    </Card>
  );
};

export default LandingCard;
