import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { whiteLogo, blackLogo } from "../../images/Header";
import { useNavigate } from "react-router-dom";
import classes from "./Header.module.css";
import { useTheme } from "../../context/useTheme";

const pages = ["Home", "About", "Projects", "Contact"];

const Header = () => {
  const navigate = useNavigate();

  const { theme } = useTheme();

  return (
    <AppBar
      position="static"
      sx={{ bgcolor: "transparent", boxShadow: "none", pt: 2 }}
    >
      <Container maxWidth="100%">
        <Toolbar>
          <img
            className={classes.img}
            src={theme === "light" ? blackLogo : whiteLogo}
            alt="E logo"
            onClick={() => navigate("/")}
          />

          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: { xs: "center", md: "flex-start" },
            }}
          >
            {pages.slice(1).map((page) => (
              <Button
                key={page}
                onClick={() => navigate(page.toLowerCase())}
                sx={{
                  my: 2,
                  color: theme === "light" ? "black" : "white",
                  display: "block",
                  textTransform: "none",
                  fontWeight: 900,
                  fontFamily: "Nunito",
                  fontSize: "17px",
                  px: { xs: 1, sm: 2 },
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#6C63FF",
                  },
                }}
              >
                {page}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
