import React, { useEffect } from "react";
import classes from "./Contact.module.css";
import { motion as m } from "framer-motion";
import PageCard from "../../components/Page_Card/PageCard";
import Email from "../../components/Email";
import { useTheme } from "../../context/useTheme";
import contactData from "../../data/contact.json";
import { Grid } from "@mui/material";
import linkedin from "../../images/Contact/linkedin.svg";
import { getAnalytics, logEvent } from "firebase/analytics";

const Contact = () => {
  const { setTheme } = useTheme();

  useEffect(() => {
    setTheme("light");
  }, [setTheme]);

  const handleClick = () => {
    const analytics = getAnalytics();

    logEvent(analytics, "linkedin_view");
  };

  return (
    <m.div
      initial={{ x: "-100%" }}
      animate={{ x: "0%" }}
      transition={{
        duration: 0.75,
        type: "spring",
        stiffness: 500,
        damping: 40,
      }}
      exit={{ opacity: 1 }}
      className={classes.page}
    >
      <PageCard color={"#75485E"} title="Contact Me">
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item sx={{ px: 1, textAlign: "center" }}>
            <a
              href={contactData.linkedinUrl}
              onClick={handleClick}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedin} alt="LinkedIn logo" />
            </a>
          </Grid>
        </Grid>
        <div className={classes.form}>
          <Email />
        </div>
      </PageCard>
    </m.div>
  );
};

export default Contact;
