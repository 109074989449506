import React, { useEffect } from "react";
import { Container, Grid } from "@mui/material";
import { motion as m } from "framer-motion";
import classes from "./Home.module.css";
import LandingCard from "./Cards/LandingCard";
import ProjectsCard from "./Cards/ProjectsCard";
import AboutCard from "./Cards/AboutCard";
import ContactCard from "./Cards/ContactCard";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../context/useTheme";

const Home = () => {
  // Used for button to naviagte to projects page
  const navigate = useNavigate();
  const { setTheme } = useTheme();

  useEffect(() => {
    setTheme("light");
  }, [setTheme]);

  return (
    <m.div
      initial={{ y: "100%" }}
      animate={{ y: "0%" }}
      transition={{
        duration: 0.75,
        type: "spring",
        stiffness: 500,
        damping: 60,
      }}
      exit={{ opacity: 1 }}
      className={classes.page}
    >
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          alignItems="stretch"
          justifyContent="center"
          sx={{ pb: { xs: 3 } }}
        >
          <Grid item xs={12} md={6} sx={{ px: 1 }}>
            <LandingCard />
          </Grid>

          <Grid item xs={12} md={6} sx={{ px: 1 }}>
            <Grid
              container
              direction="column"
              alignItems="stretch"
              justifyContent="center"
              sx={{ height: { md: "100%" } }}
            >
              <Grid
                item
                xs={12}
                md={6}
                className={classes.hoverAnimation}
                sx={{
                  width: "100%",
                  pt: { xs: 2, md: 0 },
                  pb: { xs: 1 },
                  cursor: "pointer",
                }}
                onClick={() => navigate("/projects")}
              >
                <ProjectsCard />
              </Grid>

              {/* SUBROW */}
              <Grid item xs={12} md={6}>
                <Grid
                  container
                  direction="row"
                  alignItems="stretch"
                  justifyContent="center"
                  sx={{ height: "100%" }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className={classes.hoverAnimation}
                    sx={{
                      pr: { sm: 1 },
                      pt: 1,
                      pb: { xs: 1, md: 0 },
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/about")}
                  >
                    <AboutCard />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className={classes.hoverAnimation}
                    sx={{
                      pl: { sm: 1 },
                      pt: 1,
                      pb: { xs: 1, md: 0 },
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/contact")}
                  >
                    <ContactCard />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </m.div>
  );
};

export default Home;
