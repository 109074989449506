import { useLocation } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useEffect } from "react";

const CurrScreen = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const analytics = getAnalytics();

    logEvent(analytics, "screen_view", {
      firebase_screen: pathname,
      firebase_screen_class: "firebase-routes-analytics",
    });
  }, [pathname]);
};

export default CurrScreen;
