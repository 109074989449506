import React from "react";
import classes from "./UnderlinedTitle.module.css"

const UnderlinedTitle = ({ children }) => {
  return (
    <div className={classes.page}>
      <h3>{children}</h3>
      <hr />
    </div>
  );
};

export default UnderlinedTitle;
