import React, { useEffect } from "react";
import classes from "./About.module.css";
import PageCard from "../../components/Page_Card/PageCard";
import aboutText from "../../data/about.json";
import { Grid } from "@mui/material";
import { react, swift, aws, javascript } from "../../images/About";
import { useTheme } from "../../context/useTheme";
import { motion as m } from "framer-motion";

const About = () => {
  const { setTheme } = useTheme();

  useEffect(() => {
    setTheme("light");
  }, [setTheme]);

  return (
    <m.div
        initial={{ x: "-100%" }}
        animate={{ x: "0%" }}
        transition={{ duration: 0.75, type: "spring", stiffness: 500, damping: 40 }}
        exit={{ opacity: 1 }}
        className={classes.page}
      >
      <PageCard color={"#37515F"} title="About Me">
        <div className={classes.titleBreak}>
          {[1, 2, 3].map((value, index) => (
            <p key={index}>{aboutText[value]}</p>
          ))}
        </div>

        <div className={classes.sectionBreak}>
          <h4>
            Here are some of the technologies I've been working with recently
          </h4>

          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item sx={{ px: 2, py: 2 }}>
              <img src={react} alt="React logo" />
            </Grid>
            <Grid item sx={{ px: 2, py: 2 }}>
              <img src={swift} alt="Swift logo" />
            </Grid>
            <Grid item sx={{ px: 2, py: 2 }}>
              <img src={aws} alt="AWS logo" />
            </Grid>
            <Grid item sx={{ px: 2.5, py: 2 }}>
              <img src={javascript} alt="JS logo" />
            </Grid>
          </Grid>
        </div>
      </PageCard>
    </m.div>
  );
};

export default About;
