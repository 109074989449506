import { Container, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { motion as m } from "framer-motion";
import classes from "./Projects.module.css";
import { useTheme } from "../../context/useTheme";
import UnderlinedTitle from "../../components/UnderlinedTitle/UnderlinedTitle";
import MovingParticles from "../../components/Animation/MovingParticles";
import ProjectCard from "./Card/ProjectCard";
import projects from "../../data/projects.json";

const Projects = () => {
  const { setTheme } = useTheme();

  useEffect(() => {
    setTheme("dark");
  }, [setTheme]);

  return (
    <>
      <MovingParticles />
      <m.div
        initial={{ x: "-100%" }}
        animate={{ x: "0%" }}
        transition={{ duration: 0.75, type: "spring", stiffness: 500, damping: 40 }}
        exit={{ opacity: 1 }}
        className={classes.page}
      >
        <Container sx={{ pb: 5 }}>
          <UnderlinedTitle>Projects</UnderlinedTitle>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {Object.entries(projects).map(([key, value]) => (
              <Grid item xs={12} md={6} lg={4} sx={{ p: 1 }} key={key}>
                <ProjectCard project={value} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </m.div>
    </>
  );
};

export default Projects;
